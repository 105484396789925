import fp from 'lodash/fp'
import propTypes from 'prop-types'
import React, { Component } from 'react'

import './carousel.css'

class Carousel extends Component {

    static displayName = 'Carousel'

    static propTypes = {
        images: propTypes.arrayOf(
            propTypes.shape({
                alt: propTypes.string,
                height: propTypes.number,
                src: propTypes.string.isRequired,
                uid: propTypes.string.isRequired,
                width: propTypes.number
            })
        ).isRequired,
        nbOfImagesVisible: propTypes.number.isRequired
    }

    static defaultProps = {
        nbOfImagesVisible: 1
    }

    constructor(props) {

        super(props)

        const nbOfImagesTotal = fp.size(props.images)

        this.state = {
            carouselImages: fp.slice(0, props.nbOfImagesVisible, nbOfImagesTotal),
            nbOfImagesTotal,
            step: 0
        }

    }

    componentDidMount() {

        this.interval = setInterval(this.updateImages, 2500);
    }

    componentWillUnmount() {

        clearInterval(this.interval);
    }

    updateImages = () => {

        const { props, state } = this

        const nextStep = state.step + 1 === state.nbOfImagesTotal ? 0 : state.step + 1

        let nextCarousel = []

        for (let i = 0; i < props.nbOfImagesVisible; i++) {
            nextCarousel[i] = props.images[(nextStep + i) % state.nbOfImagesTotal]
        }

        this.setState({
            carouselImages: nextCarousel,
            step: nextStep
        })
    }

    render() {

        return (

            <div className='carousel-root'>

                {fp.map(img => {

                    return (
                        <img key={img.uid} alt={img.alt} {...{
                            className: 'carousel-image',
                            height: img.height,
                            src: img.src,
                            width: img.width
                        }} />
                    )
                }, this.state.carouselImages)}

            </div>
        )
    }
}

export default Carousel
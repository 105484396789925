import React, { Component } from 'react'
import scrollToComponent from 'react-scroll-to-component';
import Carousel from './common-ui/carousel'
import About from './components/About'
import Contact from './components/Contact'
import Home from './components/Home'
import Menu from './components/Menu'
import Products from './components/Products'
import Images from './constants/images'

import './app.css'

class App extends Component {

	constructor(props) {

		super(props)

		this.Home = React.createRef()
		this.About = React.createRef()
		this.Products = React.createRef()
		this.Contact = React.createRef()
	}

	menuClicked = btn => {

		scrollToComponent(this[btn].current, {
			offset: -40, // menu height
			align: 'top',
			duration: 500
		})

		// replay the animation
		// if (btn === 'Home') {
		// 	window.location.replace('https://www.GoodGoodsImport.com')
		// }
	}

	render() {

		return (

			<div className='root'>
				<Menu menuClicked={this.menuClicked} />
				<Home ref={this.Home} />
				<About ref={this.About} />
				<div className='carousel'>
					<Carousel images={Images} nbOfImagesVisible={3} />
				</div>
				<Products ref={this.Products} />
				<Contact ref={this.Contact} />
			</div>
		)
	}
}

export default App
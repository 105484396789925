export default [{
	alt: 'Olive Oil',
	height: 232,
	src: './products-images/olive.jpg',
	srcSide: './products-images/olive-side-frame.jpg',
	width: 350,
	uid: 'OliveOil'
},
{
	alt: 'Honey',
	height: 232,
	src: './products-images/honey.jpg',
	srcSide: './products-images/honey-side-frame.jpg',
	width: 350,
	uid: 'Honey'
},
{
	alt: 'Mountain Tea',
	height: 232,
	src: './products-images/tea.jpg',
	srcSide: './products-images/tea-side-frame.jpg',
	width: 350,
	uid: 'MountainTea'
},
{
	alt: 'Mastiha',
	height: 232,
	src: './products-images/mastiha.jpg',
	srcSide: './products-images/mastiha-side-frame.jpg',
	width: 350,
	uid: 'Mastiha'
},
{
	alt: 'Safran',
	height: 232,
	src: './products-images/safran.jpg',
	srcSide: './products-images/safran-side-frame.jpg',
	width: 350,
	uid: 'Safran'
},
{
	alt: 'Wine',
	height: 232,
	src: './products-images/wine.jpg',
	srcSide: './products-images/wine-side-frame.jpg',
	width: 350,
	uid: 'Wine'
},
]
import React, { Component } from 'react'
import { translate } from 'react-multi-lang'
import addBrTag from '../utils/add-br-tag'

import './home.css';

class Home extends Component {

	static displayName = 'Home'

	onLogoClicked = () => {

		window.location.replace('https://www.GoodGoodsImport.com')
	}

	render() {

		const { t } = this.props

		return (<div className='home' >

			<img alt='home' {...{
				className: 'main-image',
				src: './products-images/main.jpg',
			}} />

			<div className='hover-wrapper'>
				<div className='logo-hover-main-img'>
					<img key='logo' alt='logo' {...{
						className: 'home-logo',
						onClick: this.onLogoClicked,
						src: './assets/logo_ggi.svg',
						width: '98',
						height: '100'
					}} />

				</div>

				<h1 className='title-hover-main-img'>
					{addBrTag(t('home.intro'))}
				</h1>
			</div>

		</div>
		)
	}
}

export default translate(Home)
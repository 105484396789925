import React from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import { setDefaultTranslations, setDefaultLanguage } from 'react-multi-lang'
import App from './App';
import en from './locales/en.json'
import fr from './locales/fr.json'
import gr from './locales/gr.json'

import './index.css';


setDefaultTranslations({ en, fr, gr })
setDefaultLanguage('en')

ReactDOM.render(
    <HttpsRedirect>
        <App />
    </ HttpsRedirect>
    , document.getElementById('root')
);

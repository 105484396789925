import React, { Component } from 'react'
import { translate } from 'react-multi-lang'
import addBrTag from '../utils/add-br-tag'

import './about.css';

class About extends Component {

	static displayName = 'About'

	render() {

		const { t } = this.props

		return (
			<div className='about'>

				<div className='about-text'>{addBrTag(t('about.part1'))}</div>
				<div className='about-text'>{addBrTag(t('about.part2'))}</div>
				<div className='about-text'>{addBrTag(t('about.part3'))}</div>
				<div className='about-text'>{addBrTag(t('about.part4'))}</div>

				<div className='btn-redirection-wrapper'>
					<a href="https://www.goodgoodsimport.com/shop/" target="_blank">
						<button className='btn-redirection'>{t('about.greek_product')}</button>
					</a>
					<a href="https://goodgoodsimport.com/wines/" target="_blank">
						<button className='btn-redirection'>{t('about.french_wines')}</button>
					</a>
				</div>

			</div>
		)
	}
}

export default translate(About)
import fp from 'lodash/fp';
import React from 'react';


const fpMap = fp.map.convert({ cap: false })

export default (txt, addExtra = false) => {

    const lines = fp.split('\n', txt)

    return fpMap((line, key) => (
        <div key={key}>
            <div>{line}</div>
            {addExtra && <br />}
        </div>
        ), lines)
}

import axios from 'axios';
import fp from 'lodash/fp'
import React, { Component } from 'react'
import { translate } from 'react-multi-lang'

import './contact.css';

const emailRegex = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/

class Contact extends Component {

    static displayName = 'Contact'

    constructor(props) {

        super(props)

        this.state = {
            email: '',
            firstName: '',
            info: '',
            isSuccess: false,
            lastName: '',
            message: ''
        }
    }

    onSubmit = async () => {

        const { props, state } = this

        if (!emailRegex.test(state.email)) {

            this.setState({
                isSuccess: false,
                info: props.t('contact.invalid_email')
            })

            return
        } else {
            this.setState({
                info: ''
            })
        }

        const url = './php/sendemail.php';

        const options = {
            method: 'POST',
            url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {
                emailFrom: state.email,
                message: state.message,
                subject: `GGI email - ${state.firstName} ${state.lastName}`
            }
        }

        const response = await axios(options)

        const responseOK = response && response.status === 200

        if (responseOK) {
            // let data = await response.data
            // do something with data
            // console.log("sucess", data)

            this.setState({
                email: '',
                firstName: '',
                isSuccess: true,
                info: props.t('contact.message_sent'),
                lastName: '',
                message: '',
            })

        } else {
            console.log("error axios")

            this.setState({
                isSuccess: false,
                info: props.t('contact.something_went_wrong')
            })
        }
    }

    onChange = ({ target }) => {

        this.setState({ [target.id]: target.value })
    }

    getField = id => {

        const { props, state } = this

        return (
            <div className='field'>
                <span>{props.t(`contact.${id}`)}</span>
                <input type='text' id={id} onChange={this.onChange} value={state[id]} />
            </div>
        )
    }

    render() {

        const { props, state } = this

        const disabled = fp.isEmpty(state.email)
            || fp.isEmpty(state.firstName)
            || fp.isEmpty(state.lastName)
            || fp.isEmpty(state.message)

        return (
            <div className='contact'>

                <div className='contactUs'>{props.t('contact.contact_us')}</div>

                <div className='form' >

                    <div className='fieldWrapper' >
                        {this.getField('firstName')}
                        {this.getField('lastName')}
                    </div>

                    {this.getField('email')}

                    <div className='field'>
                        <span>{props.t('contact.message')}</span>
                        <textarea rows='10' cols='50' id='message' onChange={this.onChange} value={state.message} />
                    </div>

                    <button {...{
                        disabled,
                        children: props.t('contact.send'),
                        className: 'submit',
                        onClick: this.onSubmit
                    }} />

                    <div className={state.isSuccess ? 'success' : 'error'}>
                        {state.info}
                    </div>

                </div>
            </div>
        )
    }
}

export default translate(Contact)
import fp from 'lodash/fp'
import React, { Component } from 'react'
import { translate } from 'react-multi-lang'
import Images from '../constants/images'
import addBrTag from '../utils/add-br-tag'

import './products.css';

const fpMap = fp.map.convert({ cap: false })

class Products extends Component {

	static displayName = 'Products'

	render() {

		const { t } = this.props

		return (
			<div className='products'>

				{fpMap(({ alt, srcSide, uid }, index) => {

					const TextComp = side => <div className='text'>
						<h2 className='product-title'>
							{t(`products.${uid}.title`)}
						</h2>
						<div className={`description ${side}`}>
							<div className='paragraph'>{addBrTag(t(`products.${uid}.description_part1`))}</div>
							<div className='paragraph'>{addBrTag(t(`products.${uid}.description_part2`))}</div>
							<div className='paragraph'>{addBrTag(t(`products.${uid}.description_part3`))}</div>
						</div>
					</div>

					return <div key={uid} className='wrapper'>

						{index % 2 !== 0 && TextComp('left')}

						<div className='image'>
							<img alt={alt} key={uid} {...{
								height: 300,
								src: srcSide,
								with: 300
							}} />
						</div>

						{index % 2 === 0 && TextComp('right')}

					</div>
				}, Images)}

			</div>
		)
	}
}

export default translate(Products)
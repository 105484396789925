import fp from 'lodash/fp'
import React, { Component } from 'react'
import { setLanguage, translate } from 'react-multi-lang'
import FlagEn from '../flags/FlagEn.js'
import FlagFr from '../flags/FlagFr.js'
import FlagGr from '../flags/FlagGr.js'
import BurgerMenu from '../icons/BurgerMenu.js'

import './menu.css';

const MENU_BUTTONS = [
	'Home',
	'About',
	'Products',
	'Contact'
]

const FLAG_BUTTONS = [
	{ code: 'en', icon: FlagEn() },
	{ code: 'gr', icon: FlagGr() },
	{ code: 'fr', icon: FlagFr() }
]

class Menu extends Component {

	static displayName = 'Menu'

	constructor(props) {

		super(props)

		this.state = {
			open: false
		}

		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	setWrapperRef(node) {

		this.wrapperRef = node;
	}

	handleClickOutside(event) {

		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {

			this.setState({ open: false })
		}
	}

	componentDidMount() {

		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {

		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	changeLang = lang => {

		setLanguage(lang)

		this.setState({ open: false })
	}

	openMenu = () => {

		this.setState({ open: !this.state.open })
	}

	render() {

		const state = this.state
		const { t } = this.props

		const MenuButtons = fp.map(btn => {
			return <div key={btn} {...{
				className: 'menuButton',
				children: t(`menu.${btn}`),
				onClick: () => this.props.menuClicked(btn)
			}} />
		}, MENU_BUTTONS)

		return (

			<div className='menu' >

				<div className='menuWeb'>

					<div className='menuButtonWrapper'>
						{MenuButtons}
					</div>

					<div className='flagsWrapper'>

						{fp.map(({ code, icon }) => {

							return <div key={code} {...{
								className: 'menuFlag',
								children: icon,
								onClick: () => this.changeLang(code)
							}} />
						}, FLAG_BUTTONS)}

					</div>

				</div>

				<div className='menuMobile'>

					<div {...{
						className: 'menuBurger',
						children: BurgerMenu(),
						onClick: this.openMenu
					}} />

				</div>

				{state.open && (
					<div className='menuMobileOpen'>
						<div className='menuMobileOption' ref={this.setWrapperRef}>

							{/* {MenuButtons} */}

							<div className='separator' />

							{fp.map(({ code, icon }) => {

								return <div key={code} {...{
									children: <div className='flagMobile'>
										<div className='menuFlag'>{icon}</div>
										<div>{t(`flag.${code}`)}</div>
									</div>,
									onClick: () => this.changeLang(code)
								}} />
							}, FLAG_BUTTONS)}

						</div>

					</div>
				)}

			</div >
		)
	}
}

export default translate(Menu)